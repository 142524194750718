import { ComputedRef, computed, ref, onMounted, onUnmounted } from 'vue';

type TimeDifference = {
  days: string;
  hours: string;
  mins: string;
  secs?: string;
};

export const useCountdown = (
  target: string
): { timeDifference: ComputedRef<TimeDifference> } => {
  const timeDifference = computed(() => {
    const ONE_HOUR_IN_MILLISECONDS = 1000 * 60 * 60;

    const currentDate = new Date();
    const targetDate = new Date(target);
    const difference = targetDate.getTime() - currentDate.getTime();

    const days = Math.floor(difference / (ONE_HOUR_IN_MILLISECONDS * 24))
      .toString()
      .padStart(2, '0');
    const hours = Math.floor(
      (difference % (ONE_HOUR_IN_MILLISECONDS * 24)) / ONE_HOUR_IN_MILLISECONDS
    )
      .toString()
      .padStart(2, '0');
    const mins = Math.floor(
      (difference % ONE_HOUR_IN_MILLISECONDS) / (1000 * 60)
    )
      .toString()
      .padStart(2, '0');

    return {
      days,
      hours,
      mins,
    };
  });

  return { timeDifference };
};

export const useLiveCountdown = (
  target: ComputedRef<string>
): { timeDifference: ComputedRef<TimeDifference> } => {
  const timeDifference = ref<TimeDifference>({
    days: '00',
    hours: '00',
    mins: '00',
    secs: '00',
  });

  const calculateTimeDifference = () => {
    const ONE_HOUR_IN_MILLISECONDS = 1000 * 60 * 60;

    const currentDate = new Date();
    const targetDate = computed(
      () => new Date(typeof target === 'string' ? target : target.value)
    ).value;
    const difference = targetDate.getTime() - currentDate.getTime();

    const days = Math.floor(difference / (ONE_HOUR_IN_MILLISECONDS * 24))
      .toString()
      .padStart(2, '0');

    const hours = Math.floor(
      (difference % (ONE_HOUR_IN_MILLISECONDS * 24)) / ONE_HOUR_IN_MILLISECONDS
    )
      .toString()
      .padStart(2, '0');

    const mins = Math.floor(
      (difference % ONE_HOUR_IN_MILLISECONDS) / (1000 * 60)
    )
      .toString()
      .padStart(2, '0');

    const secs = Math.floor((difference % (1000 * 60)) / 1000)
      .toString()
      .padStart(2, '0');

    timeDifference.value = {
      days,
      hours,
      mins,
      secs,
    };
  };

  onMounted(() => {
    calculateTimeDifference();
    const intervalId = setInterval(calculateTimeDifference, 100);

    onUnmounted(() => {
      clearInterval(intervalId);
    });
  });

  return { timeDifference: computed(() => timeDifference.value) };
};
